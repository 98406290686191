@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';

.phoneHeaderComponent {
  width: 100%;
  display: flex;
  align-items: center;
}

.logo {
  padding: 10px 15px 10px 10px;
}

.dummyText {
  width: 89px;
  height: 8px;
  border-radius: 6px;
  background-color: $color-white;
}

.theme1 {
  background-color: map-get($palette-theme1, quaternary);
}
.theme2 {
  background-color: map-get($palette-theme2, secondary);
}

.theme3 {
  background-color: map-get($palette-theme3, primary);
}

.theme4 {
  background-color: map-get($palette-theme4, quaternary);
}
