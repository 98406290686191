@import '../../../assets/styles/colors';
@import '../../../assets/styles/bootstrap-mixins';
@import '../../../assets/styles/variables';

.cardWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 932px;
  height: 124px;
  border: 3px solid $color-gray-border;
  box-shadow: 0px 4px 4px rgba(88, 87, 109, 0.25);
  background-color: $color-white;
  border-radius: 10px;

  @include media-breakpoint-down(lg) {
    max-width: 820px;
  }

  @include media-breakpoint-down(md) {
    max-width: 680px;
  }
}

.circle {
  height: 77px;
  width: 77px;
  border: 2px solid $color-gray-border;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  text-align: center;
  margin-left: -40px;
  margin-right: 0px;
  background-color: $color-white;

  img {
    height: 48px;
    width: 48px;
    display: block;
    margin: 12px auto;

    @include media-breakpoint-down(lg) {
      height: 50px;
      margin: 10px;
    }

    @include media-breakpoint-down(md) {
      width: none;
      margin: 10px;
    }
  }
}

.leftContent {
  font-family: $font-poppins;
  font-weight: 600;
  min-width: 250px;
  margin: auto 30px;
  align-self: flex-start;

  @include media-breakpoint-down(lg) {
    width: none;
    margin-right: 0;
    margin-left: 0;
  }

  .title {
    font-size: 23px;
    color: $color-black-2;
    line-height: 40px;

    @include media-breakpoint-down(md) {
      font-size: 18px;
    }
  }

  .transferLabel {
    color: $color-smoky;
    font-weight: 500;
    font-size: 11px;
  }

  .transferTime {
    color: $color-smoky;
    font-size: 11px;
  }
}

.rightContent {
  font-family: $font-roboto;
  max-width: 500px;
  align-self: flex-start;
  margin: auto 5px;

  @include media-breakpoint-down(md) {
    margin-left: 0;
    margin-right: 25px;
  }

  .headline {
    color: $color-smoky;
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 5px;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }

  .description {
    color: $color-smoky;
    font-weight: 400;
    font-size: 15px;

    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
  }
}
