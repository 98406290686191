@import '../../../assets/styles/colors';

$color-profile-icon: lighten($color-gray, 25%);

.inner {
  position: relative;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
}

.head {
  position: absolute;
  top: 5px;
  left: 9px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $color-profile-icon;
}

.body {
  position: absolute;
  top: 18px;
  left: 0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: $color-profile-icon;
}
