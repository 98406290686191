@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/bootstrap-mixins';

.dropup_container {
  min-width: 160px;
  margin-right: 50px;

  @media (max-width: 990px) {
    margin-right: 1px;
  }
}

.dropdown {
  a {
    color: black;
    text-decoration: none;
  }
}
