@import '../../../../assets/styles/colors';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  svg {
    margin-top: 15px;
  }

  p {
    margin-top: 20px;
    text-align: center;
  }
}
