@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/bootstrap-mixins';

.heading {
  font-family: $font-poppins;
  font-size: 30px;
  line-height: 42px;
  font-weight: 300;
  color: $color-black;
  margin-bottom: 23px;

  @media (min-height: 800px) {
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 32px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
  }

  &.containedInIFrame {
    font-size: 30px;
    line-height: 42px;
    margin-bottom: 23px;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 15px;
    }
  }
}

.small {
  font-family: $font-poppins;
  font-size: 30px;
  line-height: 35px;
  font-weight: 300;
  color: $color-black;
  margin-bottom: 23px;
}

.dark {
  color: $color-orange-heading;
  font-family: $font-roboto;
  font-size: 23px;
  font-weight: 300;
  line-height: 30px;
  margin: 30px 0 15px;

  &:first-child {
    margin-top: 0;
  }
}

.preText {
  display: inline-block;
  font-size: 14px;
}

.center {
  text-align: center;
}

.subHeading {
  font-family: $font-roboto;
  font-size: 18px;
  line-height: 27px;
  font-weight: 300;
  color: $color-gray;
  margin-bottom: 14px;

  @media (min-height: 800px) {
    font-size: 23px;
    line-height: 30px;
    margin-bottom: 25px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 13px;
    line-height: 23px;
    margin-bottom: 30px;
  }

  &.large {
    font-size: 23px;
    line-height: 30px;
    margin-bottom: 25px;
  }

  &.small {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 25px;

    @media (max-height: 895px) {
      margin-bottom: 10px;
      margin-right: 10px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 13px;
      line-height: 23px;
    }
  }

  &.extraMargin {
    margin-bottom: 40px;
  }

  &.dark {
    color: $color-orange-heading;
    font-size: 18px;
    margin-top: 0;
    &:first-child {
      margin-top: 0;
    }
  }
}

.theme {
  font-family: $font-poppins;
  font-size: 22px;
  font-weight: 300;

  @media (max-height: 750px) and (min-height: 614px) {
    font-size: 18px;
  }
}

.theme2 {
  color: _headingColor(theme2);
}

.theme3 {
  color: _headingColor(theme3);
}

.theme1 {
  color: _headingColor(theme1);
}

.theme4 {
  color: _headingColor(theme4);
}

.phone {
  margin: 15px 0 25px;
  font-size: 18px;
  font-weight: 300;
}
