@import '../../../assets/styles/colors';

.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -5px -20px 0;
  padding: 0 20px 10px;
  border-bottom: 1px solid $color-gray-theme-border;

  p {
    margin: 0 0 0 15px;
  }
}

.check {
  height: 40px;
  width: 40px;
}
