@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.StepCard {
  background-color: $color-white;
  margin: 0px auto;
  padding: 0px 45px;
  box-sizing: border-box;
  text-align: center;
  width: 350px;
  height: 350px;
  box-shadow: 0px 5px 5px $color-light-shadow;
  border-radius: 5px;
  border: solid $color-gray-border 1px;
  padding-bottom: 0px;
  text-decoration: none;
  svg {
    margin-top: -45px;
    margin-bottom: 20px;
  }

  @media (max-width: 1200px) {
    width: 309px;
    height: 309px;
    padding: 0px 35px;

    .cardContent {
      margin-bottom: 0px;
    }
  }

  @media (max-width: 991px) {
    width: 220px;
    height: 220px;
    padding: 0px 25px;

    svg {
      height: 70px;
      width: 70px;
      margin-top: -35px;
      margin-bottom: 10px;
    }
  }
}

.cardTitle {
  color: $color-gunpowder;
  font-family: $font-poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  height: 20%;
  margin-top: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;

  @media (max-width: 991px) {
    line-height: 20px;
    font-size: 16px;
  }
}

.cardContent {
  color: $color-blue-magenta;
  font-family: $font-roboto;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  height: 45%;
  @media (max-width: 991px) {
    line-height: 17px;
    font-size: 14px;
  }
}
