@import '../../../assets/styles/colors';

@keyframes preloader {
  0% {
    transform: scale(0);
    border-width: 8px;
    background-color: $color-black;
    opacity: 1;
  }

  20% {
    background-color: transparent;
  }

  100% {
    transform: scale(1);
    border-width: 2px;
    opacity: 0;
  }
}

@keyframes preloader-button {
  0% {
    transform: scale(0);
    border-width: 6px;
    opacity: 1;
  }

  20% {
    background-color: transparent;
  }

  100% {
    transform: scale(1);
    border-width: 2px;
    opacity: 0;
  }
}

%expand-dot {
  position: absolute;
  top: 0;
  left: 0;
  width: 44px;
  height: 44px;
  border-style: solid;
  border-radius: 50%;
  border-color: $color-gray;
  content: '';
  opacity: 0;
  animation-name: preloader;
  animation-duration: 1.8s;
  animation-timing-function: cubic-bezier(0.3, 0.61, 0.355, 1);
  animation-iteration-count: infinite;
}

.preloader {
  position: relative;
  width: 44px;
  height: 44px;
  margin: 0 auto;

  span {
    display: none;
  }

  &::after {
    @extend %expand-dot;
  }

  &::before {
    @extend %expand-dot;

    animation-delay: 0.9s;
  }
}

.dark {
  &::after,
  &::before {
    border-color: $color-white;
  }
}

.preloader-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 22px;
  height: 22px;
  margin: -11px 0 0 -11px;

  &::after,
  &::before {
    width: 22px;
    height: 22px;
    border-color: $color-white;
    animation-name: preloader-button;
  }

  &.dark {
    &::after,
    &::before {
      border-color: $color-gray;
    }
  }
}
