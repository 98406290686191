/* Fonts */
$font-poppins: 'Poppins', sans-serif;
$font-roboto: 'Roboto', sans-serif;

/* Animation Timing Functions */
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);

/* Misc. */
$phone-max-height: 550px;
$phone-max-width: 275px;
