@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/bootstrap-mixins';

.savingsContainer {
  margin: 35px 0 15px;
  padding: 35px;
  background-image: $gradient-light;
  color: $color-white;

  @include media-breakpoint-down(xs) {
    margin-top: 15px;
    padding: 28px 10px 20px;
    font-size: 13px;
  }
}

.savings {
  margin-bottom: 0;
  font-family: $font-poppins;
  font-size: 60px;

  @include media-breakpoint-down(xs) {
    font-size: 36px;
  }

  > span {
    vertical-align: super;
    font-size: 36px;

    @include media-breakpoint-down(xs) {
      font-size: 24px;
    }
  }
}

.disclaimer {
  margin: 0 20px 30px 20px;
  font-size: 14px;
  color: $color-gray;

  @include media-breakpoint-down(xs) {
    font-size: 12px;
  }
}

.preloaderWrapper {
  margin: 23px 0 23px;

  @include media-breakpoint-down(xs) {
    margin: 5px 0 5px;
  }
}
