@import '../../../../assets/styles/colors';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;

  div {
    text-align: center;
  }
}

.text {
  margin-bottom: 30px;
}

.check {
  height: 102px;
  width: 102px;
  margin-bottom: 20px;
}
