@import '../../../assets/styles/colors';

.note {
  padding: 20px 20px 20px 15px;
  color: $color-code;
  background-color: lighten($color-purple-code, 5%);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-width: 0 0 0 1px;
  border-style: solid;
  border-image: linear-gradient(
    to bottom,
    $color-gradient-pink,
    $color-gradient-orange
  );
  border-image-slice: 1;
  font-size: 14px;

  code {
    color: $color-code-pink;
    font-size: 14px;
  }
}
