$theme-colors: (
  'primary': $color-gray,
  'secondary': $color-orange,
  'light': $color-white,
  'dark': $color-black,
);
$link-color: $color-orange;

@import 'node_modules/bootstrap/scss/bootstrap';
@import '../styles/colors';

a {
  text-decoration: underline;
}

.app-builder {
  background-image: url('../images/global/demo-gray-wave.svg');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-position: 0% 83%;

  @media (min-height: 700px) {
    background-position: 0% 93%;
  }
}

.step-overview {
  background-image: url('../images/global/demo-gray-wave.svg');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-position: 0% 83%;

  @media (min-height: 700px) {
    background-position: 0% 93%;
  }
}
.container {
  padding-top: 35px;
  padding-bottom: 35px;

  &__center-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__center-content_funds {
    flex-grow: 1;
    display: flex;
    min-width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      top: 0;
      background-color: #58576d;
      height: 50px;
    }
  }
}

.progress {
  height: 1px;
  background-color: $color-purple-darker;
  border-radius: 0;

  &.gray {
    background-color: $color-light-gray;
  }
}

.progress-bar {
  background-image: $gradient-main;
}

.btn {
  border-radius: 5px;
  padding: 16px 39px;
  cursor: pointer;

  &.disabled,
  &:disabled {
    cursor: not-allowed;
  }
}

.btn-outline-light:hover {
  path {
    fill: $color-black;
  }
}

.btn-pill-gray {
  width: 155px;
  height: 48px;
  font-family: $font-roboto;
  font-size: 15px;
  font-weight: 300;
  margin: 0 50px;
  padding: 15px 0px;
  border-radius: 10px !important;
  color: $color-smoky;
  display: flex;
  background: linear-gradient($color-alice-blue, $color-alice-blue) padding-box,
    linear-gradient(45deg, $color-alice-blue, $color-alice-blue) border-box;
  box-shadow: 0px 3px 3px rgba($color-purple-code, 0.45);
  border: 2px solid transparent;
  align-items: center;
  justify-content: center;

  &:hover {
    color: $color-gunpowder;
  }

  &:focus,
  &.focus,
  &:hover,
  &:active,
  &.active {
    background: linear-gradient(#fff, #fff) padding-box,
      linear-gradient(45deg, $color-gradient-orange, $color-gradient-pink)
        border-box;
  }

  &.active {
    box-shadow: 0px 5px 3px rgba($color-purple-code, 0.55);
    font-weight: 400;
  }

  @media (max-width: 1000px) {
    width: 110px;
    height: 60px;
  }
}

.show > .btn-dropup-menu {
  color: $color-black;
  background-color: $color-white;
}

.dropdown-item {
  font-size: 0.875rem;

  @media (max-height: 750px) {
    font-size: 14px;
  }
}

.btn-dropup-menu {
  background-color: $color-orange;
  border: none;
  color: $color-white;
  margin-top: 5px;
  padding: 15px 39px;

  &:hover {
    background-color: $color-white;
  }

  @include media-breakpoint-down(md) {
    margin-right: 0px;
    padding: 10px;
  }

  @media (max-height: 750px) {
    padding: 12px 39px;
    font-size: 14px;
  }
}

.btn-pill-white {
  color: $color-white;
  font-family: $font-poppins;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  padding: 8px 16px;
  margin: 0 10px;
  border-radius: 17px !important;

  &.active {
    color: $color-dark-gray;

    &:hover {
      color: $color-dark-gray;
    }
  }

  &:hover {
    color: $color-white;
  }

  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  }

  &.active {
    background-color: $color-white;
  }
}

.btn-card {
  font-family: $font-poppins;
  color: $color-black;
  font-size: 15px;
  line-height: 23px;
  font-weight: 500;
  background-color: $color-white;
  box-shadow: 0 15px 25px 0 rgba(218, 225, 232, 0.7);
  border: 2px solid $color-white;
  border-radius: 0;
  padding: 30px;
  width: 45%;
  margin: 0 15px 30px 15px !important;

  &.focus,
  &.active {
    border: 2px solid $color-orange-light;
    box-shadow: 0 15px 25px 0 rgba(218, 225, 232, 0.7);
  }

  @media (max-height: 896px) {
    height: 190px;
  }

  @media (max-height: 800px) {
    height: 175px;
  }

  @media (max-height: 773px) {
    padding: 25px;
    height: 170px;
  }
}

.btn-dwolla {
  @include button-variant($color-purple, $color-purple);
}

.btn-theme1 {
  @include button-variant(_UIColor(theme1), _UIColor(theme1));
}
.btn-theme2 {
  @include button-variant(_UIColor(theme2), _UIColor(theme2));
}

.btn-theme3 {
  @include button-variant(_UIColor(theme3), _UIColor(theme3));
}

.btn-theme4 {
  @include button-variant(_UIColor(theme4), _UIColor(theme4));
}

.form-label {
  font-size: 12px;
  color: $color-gray;
  text-transform: uppercase;
}

.form-control,
.input-group-text {
  font-size: 14px;
}
