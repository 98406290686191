@import '../../../assets/styles/bootstrap-mixins';

.leftLogo {
  position: absolute;
  width: 40%;
  bottom: 5px;
  left: -100px;
  z-index: 1;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.rightLogo {
  position: absolute;
  max-width: 25%;
  top: -80px;
  right: -160px;
  z-index: 1;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}
