@import './colors';

@mixin getThemeColors($colorKey) {
  .theme1 {
    background-color: getColorFromPalette($palette-theme1, $colorKey);
  }
  .theme2 {
    background-color: getColorFromPalette($palette-theme2, $colorKey);
  }

  .theme3 {
    background-color: getColorFromPalette($palette-theme3, $colorKey);
  }

  .theme4 {
    background-color: getColorFromPalette($palette-theme4, $colorKey);
  }
}
