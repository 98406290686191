@import '../../../assets/styles/colors';
@import '../../../assets/styles/bootstrap-mixins';

.conclusionPage {
  background-image: $gradient-light;
  color: $color-white;
  position: relative;
  overflow: hidden;
}

.conclusionContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
  padding: 40px 80px;
  flex: 1 2;
  z-index: 99;

  h1,
  h2,
  a {
    color: $color-white;
  }

  .textContainer {
    margin-top: 150px;
    width: 40%;

    h1 {
      font-size: 36px;
      font-weight: 700;
      line-height: 40px;
    }

    h2 {
      font-size: 30px;
      font-weight: 200;
      line-height: 40px;
    }

    @include media-breakpoint-down(lg) {
      width: 100%;
      margin-top: 50px;

      h1 {
        font-size: 32px;
        font-weight: 700;
        line-height: 35px;
        text-align: center;
      }

      h2 {
        font-size: 26px;
        font-weight: 200;
        line-height: 30px;
        text-align: center;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 0;
    gap: 0;
    display: grid;
    justify-content: center;
  }
}

.formContainer {
  width: 80%;
  margin-left: 50px;
  border-radius: 6px;
  box-shadow: 0px 7px 5px rgba($color-purple-code, 0.75);

  @include media-breakpoint-down(lg) {
    width: 100%;
    margin: 0 auto;
  }
}

.card {
  background-color: $color-purple-form;
  padding: 25px;
  border-radius: 6px;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 20px;

  h1,
  h2,
  a {
    color: $color-white;
  }

  a {
    margin-top: 25px;
  }
}
