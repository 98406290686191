@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/bootstrap-mixins';

.mobileGuard {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: $gradient-light;
  color: $color-white;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.content {
  text-align: center;
  background-color: $color-purple;
  padding: 25px;
  border-radius: 6px;
}

.message {
  font-family: $font-poppins;
  font-size: 30px;
  line-height: 42px;
  font-weight: 300;
  margin-bottom: 30px;
}
