@import '../../../../assets/styles/colors';

.container {
  margin-top: 15px;
}

.icon {
  margin-bottom: 10px;
}

.fundingSource {
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 300;
}

.balance {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 300;
}

.status {
  margin-bottom: 0;
  font-weight: 300;
  text-transform: uppercase;
}

.verified {
  color: $color-success-text;
}

.unverified {
  color: $color-warning-text;
}
