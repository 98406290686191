@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/bootstrap-mixins';

.hero {
  background-color: $color-valhalla;
  width: 100%;
  min-height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-height: 895px) {
    min-height: 0px;
    height: 180px;
  }
}
