@import '../../../../assets/styles/colors';

.container {
  margin-top: 15px;
}

.friend {
  margin: 15px 0 25px;
  font-size: 18px;
  font-weight: 300;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
