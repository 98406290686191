@import '../../../assets/styles/variables';
@import '../../../assets/styles/colors';
@import '../../../assets/styles/bootstrap-mixins';

.bg {
  background-color: $color-purple-code;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-height: $phone-max-height;
  flex-grow: 1;
}

.phoneWrap {
  position: relative;
  width: 55%;

  @include media-breakpoint-up(md) {
    width: 45%;
  }

  @include media-breakpoint-up(lg) {
    width: 35%;
  }
}

.codeWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 45%;

  @include media-breakpoint-up(md) {
    width: 55%;
  }

  @include media-breakpoint-up(lg) {
    width: 65%;
  }
}

.phoneContents {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 60px);
  padding: 15px 20px;
  font-size: 14px;

  button {
    font-size: 14px;
  }
}

.noShrink {
  flex-shrink: 0;
}

.dashboard {
  flex-grow: 1;
  margin-top: 25px;
}

.processing {
  min-height: calc(100% - 20px);
}

.processingImg {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;

  img {
    max-height: 400px;
  }
}

.note {
  margin-top: 26px;
}
