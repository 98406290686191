@import '../../../assets/styles/colors';
@import '../../../assets/styles/bootstrap-mixins';

.paymentTypesPage {
  background-image: $gradient-light;
  color: $color-white;
  overflow: hidden;
  position: relative;

  h1 {
    font-size: 34px;
    font-weight: 600;
    color: $color-white;

    @include media-breakpoint-down(lg) {
      font-size: 30px;
    }

    @include media-breakpoint-down(md) {
      font-size: 25px;
    }
  }
}
