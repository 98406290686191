@import '../../../assets/styles/colors';
@import '../../../assets/styles/bootstrap-mixins';

.pageNavWrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
}

.pageNavComponent {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  margin-top: auto;
  padding: 20px 40px;

  @media (max-height: 750px) {
    padding: 15px 30px;
  }

  @include media-breakpoint-down(sm) {
    padding: 20px 30px;
  }
}

.logo {
  height: 40px;
  margin-left: 50px;
  width: 160px;

  svg {
    width: 100%;
    height: 100%;
  }

  @include media-breakpoint-down(md) {
    margin-left: 0px;
    margin-top: 3px;
  }
}

.buttons {
  flex-shrink: 0;
  display: flex;
  gap: 20px;
  align-items: center;
  padding-top: 5px;

  @media (max-height: 750px) {
    margin-right: 10px;
  }

  @include media-breakpoint-down(md) {
    gap: 10px;
  }
}

.button {
  padding: 13px 25px;
  border: 2px solid;

  @media (max-height: 750px) {
    margin-left: 10px;
    padding: 8px 19px;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 10px;
    padding: 14px 12px;
  }

  @include media-breakpoint-down(md) {
    padding: 8px 19px;
  }
}

.resetButton {
  padding: 8px 8px;
  border: 2px solid;

  @media (max-height: 750px) {
    margin-left: 10px;
    padding: 3px 3px;
  }

  svg {
    height: 30px;
    width: 30px;
  }
  @include media-breakpoint-down(sm) {
    margin-left: 10px;
    padding: 3px 3px;
  }

  @include media-breakpoint-down(md) {
    padding: 3px 3px;
  }
}

.buttonText {
  @media (max-height: 750px) {
    font-size: 14px;
    line-height: 14px;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.buttonNext {
  background: linear-gradient($color-purple-dark, $color-purple-dark)
      padding-box,
    linear-gradient(233deg, #953091 -30%, #f68f44 100%) border-box;
  border-radius: 4px;
  border: 2px solid transparent;

  &:hover {
    background: $color-white;
    color: $color-black;
  }
}

.buttonIcon {
  width: 24px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.progressNav {
  position: absolute;
  height: 10px;
  width: 100%;
  top: -10px;
}

.gradient {
  background-image: $gradient-main;
}

.purple {
  background-color: $color-valhalla;
}

.white {
  background-color: $color-white;
  border-top: 1px solid $color-light-gray;
}

.pending {
  position: relative;

  span {
    visibility: hidden;
  }
}
