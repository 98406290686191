.button {
  width: 100%;
}

.pending {
  position: relative;

  span {
    visibility: hidden;
  }
}
