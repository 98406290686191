@import '../../../assets/styles/variables';
@import '../../../assets/styles/colors';
@import '../../../assets/styles/bootstrap-mixins';
@import '../../../assets/styles/keyframes';

.videoLoop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 55px;

  @media (min-height: 800px) {
    padding: 20px 0px;
  }
}

.animate {
  animation: fadeIn 800ms;
}

.resize {
  video {
    height: 135%;
    max-height: 600px;
    margin-top: -65px;
  }

  @media (max-height: 800px) {
    video {
      height: 110%;
    }
  }

  @media (max-height: 650px) {
    video {
      margin-top: -50px;
    }
  }
}

.sub_headline {
  font-family: $font-roboto;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  color: $color-blue-magenta;
  padding: 0px 30px;
  z-index: 1;
  position: relative;
}
