@import './assets/styles/bootstrap-mixins';

.router > * {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 98px;

  @media (max-height: 750px) {
    padding-bottom: 72px;
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 91px;
  }
}

.containedInIFrame > * {
  min-height: unset;
}
