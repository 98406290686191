.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-bottom: 60px;

  @media (max-height: 737px) {
    margin-bottom: 86px;
  }

  @media (max-height: 613px) {
    flex-grow: 0;
    margin-bottom: 0;
    margin-top: 20px;
  }
}

.visuallyHidden {
  visibility: hidden;
  height: 0;
}
