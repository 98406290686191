@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.steps {
  display: flex;
  width: 100%;
  align-items: center;
}

.steps :first-child {
  margin-left: 0px;
}

.steps :last-child {
  margin-right: 0px;
}
