.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;

  p {
    margin-top: 10px;
    text-align: center;
  }
}
