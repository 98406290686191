@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/bootstrap-mixins';

.headline {
  font-family: $font-poppins;
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  color: $color-alice-blue;
  width: 100%;
  margin-bottom: 5px;
}

.subHeadline {
  font-family: $font-roboto;
  font-size: 24px;
  line-height: 28px;
  font-weight: 300;
  color: $color-mischka-grey;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 3px;
  padding: 0 20px;

  @include media-breakpoint-down(md) {
    font-weight: 400;
    font-size: 20px;
  }
}

.preText {
  display: inline-block;
  font-size: 14px;
}

.description {
  font-family: $font-roboto;
  font-size: 18px;
  line-height: 21px;
  font-weight: 300;
  color: $color-mischka-grey;
  width: 100%;
}
