/* Colors */
$color-orange: #ca4c2f;
$color-orange-light: #e37c53;
$color-orange-heading: #f79d52;
$color-purple: #483d66;
$color-purple-dark: #282840;
$color-purple-darker: #222338;
$color-purple-code: #2d2d48;
$color-purple-border: #474669;
$color-purple-form: #2e2d48;
$color-white: #fff;
$color-black: #2a2d38;
$color-gray: #6a7282;
$color-light-gray: #dfe9f0;
$color-dark-gray: #535966;
$color-gray-background: #f0f6fa;
$color-gray-border: #aab7c8;
$color-gray-theme-border: #d8e0e5;
$color-light-gray-border: #d0dfe9;
$color-dot-bg: #ffdede;
$color-dot-line: #fde1ca;
$color-code: #c0c5ce;
$color-code-pink: #b45ea4;
$color-success-text: #58b467;
$color-warning-text: #ff9800;
$color-alice-blue: #f4f7fc;
$color-mischka-grey: #abacb7;
$color-smoky: #58576d;
$color-gunpowder: #4a4a5e;
$color-blue-magenta: #434259;
$color-light-shadow: #2d2d4855;
$color-dark-shadow: #2d2d488d;
$color-black-2: #292948;
$color-valhalla: #2e2d48;
$color-video-background: #343453;

/* Gradients */
$color-gradient-orange: #f5833d;
$color-gradient-pink: #b54d66;
$color-gradient-purple: #953091;
$gradient-main: linear-gradient(
  45deg,
  $color-gradient-orange 0%,
  $color-gradient-pink 100%
);
$gradient-light: linear-gradient(
  90deg,
  lighten($color-gradient-orange, 3%) 0%,
  lighten($color-gradient-pink, 3%) 100%
);
$gradient-border: linear-gradient(
  233deg,
  $color-gradient-purple -30%,
  $color-gradient-orange 100%
);

/* Palettes */
$palette-theme1: (
  primary: #ff3366,
  secondary: #2f7cbd,
  tertiary: #e9ebec,
  quaternary: #323b45,
);
$palette-theme2: (
  primary: #1e5497,
  secondary: #91d9f4,
  tertiary: #4ca2da,
  quaternary: #dfe1e5,
);

$palette-theme3: (
  primary: #e95f5c,
  secondary: #314855,
  tertiary: #79ceb8,
  quaternary: #ffdb00,
);

$palette-theme4: (
  primary: #479ccf,
  secondary: #96bf48,
  tertiary: #eaedef,
  quaternary: #2d3538,
);

$palette-ui-element: (
  theme1: map-get($palette-theme1, primary),
  theme2: map-get($palette-theme2, primary),
  theme3: map-get($palette-theme3, primary),
  theme4: darken(map-get($palette-theme4, secondary), 10%),
);

$palette-heading: (
  theme1: map-get($palette-theme1, quaternary),
  theme2: map-get($palette-theme2, primary),
  theme3: map-get($palette-theme3, secondary),
  theme4: map-get($palette-theme4, quaternary),
);

@function _UIColor($palette) {
  @return map-get($palette-ui-element, $key: $palette);
}

@function _headingColor($palette) {
  @return map-get($palette-heading, $key: $palette);
}

@function getColorFromPalette($palette, $colorKey) {
  @if map-has-key($map: $palette, $key: $colorKey) {
    $returnColor: map-get(
      $map: $palette,
      $key: $colorKey,
    );
    @return $returnColor;
  }
  @return null;
}
