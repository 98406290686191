.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  p {
    margin-top: 20px;
    text-align: center;
  }
}

.icon {
  margin-bottom: 20px;
}
