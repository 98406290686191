@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';

.phoneInterfaceComponent {
  position: relative;
  max-height: $phone-max-height;
  max-width: $phone-max-width;
  height: 62vh;
  width: 31vh;

  @media (max-height: 613px) {
    height: unset;
    width: unset;
  }
}

.phoneImage {
  position: relative;
  max-width: 100%;
  pointer-events: none;
  z-index: 888;
}

.phoneScreen {
  position: absolute;
  top: 8.4%;
  left: 6.2%;
  right: 6.2%;
  bottom: 2.6%;
  background-color: $color-white;
  overflow-x: hidden;
  overflow-y: auto;
}

.noScrolling {
  overflow-y: hidden;
}

.left {
  margin-right: auto;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.right {
  margin-left: auto;
}
