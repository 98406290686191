@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/bootstrap-mixins';

.inputCol {
  color: $color-white;
  background-color: $color-purple-dark;
}

.costCol {
  display: flex;
  flex-direction: column;
  background-color: $color-gray-background;
  border: 1px solid $color-light-gray-border;
}

.colHeading {
  margin: 25px 0 0;
  font-family: $font-roboto;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  text-align: center;

  @include media-breakpoint-down(xs) {
    font-size: 16px;
  }
}

.twoColForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 18px 0 14px;

  > div {
    width: calc(50% - 28px);

    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }

  label,
  .label {
    font-family: $font-roboto;
    font-size: 11px;
    line-height: 16.5px;
    color: darken($color-light-gray, 5%);
    text-transform: uppercase;
    letter-spacing: 0.88px;
  }

  .label {
    margin-top: 7px;
  }
}

.totalContainer {
  margin-bottom: 1rem;
}

.total {
  padding: 0.5rem 1rem;
  border: 1px solid $color-white;
  font-size: 14px;
  overflow: auto;
}

.currentCost {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-family: $font-poppins;
  font-size: 60px;

  @include media-breakpoint-down(xs) {
    font-size: 36px;
  }

  > div {
    overflow: auto;
  }

  span {
    vertical-align: super;
    font-size: 36px;

    @include media-breakpoint-down(xs) {
      font-size: 24px;
    }
  }
}
