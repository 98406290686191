@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/bootstrap-mixins';

.stepComponent {
  display: flex;
  align-items: center;
  white-space: nowrap;

  @include media-breakpoint-up(md) {
    flex-direction: column;
    width: 46px;
  }
}

.stepNumber {
  font-family: $font-poppins;
  font-size: 22px;
  line-height: 36px;
  font-weight: 300;
  width: 36px;
  height: 36px;
  border: 1px solid $color-white;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-height: 750px) {
    font-size: 16px;
    line-height: 26px;
    width: 26px;
    height: 26px;
  }

  @include media-breakpoint-down(sm) {
    margin-right: 10px;
  }

  &.current {
    background-color: $color-white;
    color: $color-purple-dark;
  }

  &.complete {
    color: $color-orange-light;
    border: 1px solid $color-orange-light;
  }

  &.gray {
    color: $color-gray;
    border: 1px solid $color-light-gray-border;

    &.current,
    &.complete {
      background-color: $color-white;
      color: $color-orange-light;
      border: 1px solid $color-orange-light;
    }
  }
}

.stepCheck {
  @media (max-height: 750px) {
    max-width: 16px;
  }
}

.stepLabel {
  font-family: $font-poppins;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;

  @include media-breakpoint-up(md) {
    margin-top: 15px;

    @media (max-height: 750px) {
      margin-top: 5px;
    }
  }
}
