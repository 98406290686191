.container {
  margin: auto;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-grow: 1;

  @media (max-height: 699px) {
    margin: 7.5% auto;
  }
}
