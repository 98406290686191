@import '../../../assets/styles/variables';
@import '../../../assets/styles/colors';
@import '../../../assets/styles/bootstrap-mixins';

.bubble {
  @include media-breakpoint-down(md) {
    max-width: 300px !important;
  }

  [data-tour-elem='controls'] {
    justify-content: center;
  }
}

.heading {
  font-family: $font-poppins;
  color: $color-black;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
}

.content {
  font-family: $font-roboto;
  color: $color-gray;
  font-size: 15px;
  line-height: 25px;
}

.mask {
  opacity: 0.5;
}

.maskHighlight {
  display: block;
  pointer-events: none;
  stroke: $color-white;
  stroke-width: 2px;
}
