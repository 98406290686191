@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/bootstrap-mixins';

.stepIndicatorComponent {
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    padding: 30px;
    align-items: flex-start;
    flex-direction: column;
  }
}

.stepLine {
  border-top: 1px solid $color-white;
  flex-grow: 1;
  align-self: flex-start;
  margin-top: 46px;

  @media (max-height: 750px) {
    margin-top: 28px;
  }

  @include media-breakpoint-down(sm) {
    border-top: none;
    border-left: 1px solid $color-white;
    height: 10px;
    margin: 0 18px;

    @media (max-height: 750px) {
      margin: 0 13px;
    }
  }

  &.gray {
    border-top: 1px solid $color-light-gray-border;

    @include media-breakpoint-down(sm) {
      border-top: none;
      border-left: 1px solid $color-light-gray-border;
    }
  }

  &.orange {
    border-top: 1px solid $color-orange-light;

    @include media-breakpoint-down(sm) {
      border-top: none;
      border-left: 1px solid $color-orange-light;
    }
  }
}
