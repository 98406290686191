@import '../../../assets/styles/colors';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/bootstrap-mixins';

.homePage {
  background-color: $color-video-background;
  color: $color-white;

  .container {
    padding-top: unset;
    padding-bottom: unset;
  }

  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    min-height: 150px;
    font-size: 34px;
    line-height: 51px;
    padding: 0px 30px;
    color: $color-white;
    background-color: $color-valhalla;
    margin-bottom: unset;

    @media (max-width: 918px) {
      font-size: 28px;
    }
  }

  video {
    overflow: hidden;
    min-height: 550px;
    clip-path: inset(2px 2px);
    min-height: 550px;

    @include media-breakpoint-down(lg) {
      min-height: 520px;
    }

    @include media-breakpoint-down(md) {
      min-height: 475px;
    }
  }
}
