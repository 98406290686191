@import '../../../../assets/styles/colors';

.admin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -5px -20px 15px;
  padding: 0 20px 10px;
  border-bottom: 1px solid $color-gray-theme-border;

  p {
    margin-bottom: 0;
  }
}
