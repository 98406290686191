@import '../../../assets/styles/variables';
@import '../../../assets/styles/colors';
@import '../../../assets/styles/bootstrap-mixins';

.logoWrap {
  display: flex;
  align-items: center;
  height: 100%;

  &:hover {
    text-decoration: none;
  }
}

.text {
  font-family: $font-poppins;
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  color: $color-gray;
  text-transform: uppercase;
  white-space: nowrap;
  text-align: right;
  margin-right: 10px;
}

.mobileOnly {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.logo {
  height: 100%;
}

.fullLogo {
  max-width: 155px;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.markLogo {
  max-width: 40px;

  @include media-breakpoint-up(md) {
    display: none;
  }
}
