body {
  margin: 0;
  box-sizing: border-box;
  font-family: $font-roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#dwollaCookieConsent {
  p {
    font-family: 'Roboto', sans-serif;
  }

  a {
    transition: color 0.4s ease;
    text-decoration: underline;
    color: #ca4c2f;

    &:hover {
      color: #a13c25;
    }
  }
}
