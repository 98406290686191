@import '../../../assets/styles/colors';

.background {
  max-height: 100%;
  padding: 4px 10px 4px 4px;
  background-color: $color-purple-dark;
  border-radius: 4px;
  overflow: auto;

  pre {
    margin-bottom: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-purple-code;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: $color-purple-code;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: lighten($color-purple-code, 5%);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: lighten($color-purple-code, 10%);
  }

  &::-webkit-scrollbar-corner {
    background-color: $color-purple-code;
  }
}
