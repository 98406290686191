@import '../../../assets/styles/colors';

.theme1 {
  :global(.payouts-group) {
    stroke: _UIColor(theme1);
  }
}
.theme2 {
  :global(.payouts-group) {
    stroke: _UIColor(theme2);
  }
}

.theme3 {
  :global(.payouts-group) {
    stroke: _UIColor(theme3);
  }
}

.theme4 {
  :global(.payouts-group) {
    stroke: _UIColor(theme4);
  }
}
